<template>
  <div class="row d-flex">
    <slot>
      <label class="text-right font-weight-bold" :class="labelClass || 'col-sm-5'">{{label}} :</label>
      <div v-html="value" :class="valueClass || 'col-sm-7'"></div>
    </slot>
  </div>
</template>

<script>
export default {

  props: {

    label: {

      default: '',
      type: String,
    },
    labelClass: {

      default: '',
      type: String,
    },
    value: {

      default: '',
      type: [Number, String],
    },
    valueClass: {

      default: '',
      type: String,
    },
  },
};
</script>
